// src/sections/Hero.js

import React from 'react';
import { Link } from 'react-scroll';

import HeaderSimple from "../components/HeaderSimple";

export default function Hero() {
    return(
        <section className='hero' id='content'>            
            <div className='inner'>

                <HeaderSimple />

                <div className='container'>
                    <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt='' className='logo' />
                    <h1>Designing the Future of Brands, Experiences, and Stories</h1>
                    <p className='large'>We find innovative solutions to make your business better. View some of our recent work, or contact us.</p>
                    <div className='button-group center'>
                    <Link
                        to="work"
                        href="#work"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="btn work"
                        rel="nofollow"
                    >
                    View our work
                    </Link>
                    <Link
                        to="contact"
                        href="#contact"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="btn secondary contact"
                        rel="nofollow"
                    >
                    Contact us
                    </Link>
                    </div>
                </div>

                <div className='hero-flair-blue'></div>
                <div className='hero-flair-yellow'></div>
                <div className='hero-flair-red'></div>
            </div>
        </section>
    );
}