// src/pages/ManOfParts.js

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Close from '../components/Close';

export default function ManOfParts() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Close />
            <section className='hero case-study'>            
                <img src={process.env.PUBLIC_URL + '/images/work/mop/CaseStudy-ManOfParts-Top.jpg'} alt='Man of Parts' />
            </section>

            <section className='section'>
                <div className='narrow'>
                    <span className='eyebrow'>Case Study</span>
                    <h2>Man of Parts</h2>
                    <p className='XLarge true-blue'>A person of many talents and endeavours, a Man of Parts is free to explore, question, and evolve. No longer constrained by borders, styles, or roles, they seek out a diversity of perspectives.</p>
                    <p>Man of Parts is a postnational brand with design that reflects the world, enabling it to flow between the varied contexts you create with ease. We're open-minded by design.</p>
                </div>
            </section>

            <section className='section light-blue'>
                <div className='content alt'>
                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>The Challenge</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>With a growing list of high-end international showrooms and distribution channels, Man of Parts needed an online presence that catered to their wide range of clientele, while upholding the polish of the brand.</p>
                            <p>High impact visuals needed to be seamlessly integrated with contextual details for 3 separate user segments. Easy to use for repeat users, while still keeping that wow factor for clients visiting for the first time.</p>
                        </div>
                    </div>

                    <div className='clear sm'></div>

                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>Our Approach</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>GLK was brought in to assess the current online model, determine gaps in the user experience, and recommend UX and UI changes. We put together a detailed report, including a breakdown of user segments and highlighted any areas in the UX that could be improved, working with their internal development team to execute. The updated website will be online shortly.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section no-bottom'>
                <div className='narrow'>
                    <span className='eyebrow dark'>Overview</span>
                    <p className='XLarge true-blue'>A Man of Parts thinks of time as the most desirable luxury. There is no need for craft to be stuck in the past, so they provide civil, considerate, and swift service.</p>
                    <p>One of the biggest challenges for GLK revolved around tweaking the UI so that all 3 user segments could quickly and easily achieve their goals. As an added layer of complexity – big, bold images needed to be showcased like a glossy magazine, while maintaining a hierarchy of content, context and functionality.</p>
                </div>

                <div className='clear xl'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/mop/CaseStudy-ManofParts-Process.jpg'} alt='Man of Parts' />
                </div>

                <div className='clear md'></div>

                <div className='narrow'>
                    <h4>Digging a little deeper</h4>
                    <p className='XLarge true-blue'>A Man of Parts never needs to shout to be heard. Designed with cohesion in mind, our products reveal their stand-out attributes over time and fit within whatever context you create.</p>
                    <p>After doing a complete analysis of the current website, we were able to narrow in on user pain points and behind the scenes complications. We compiled a complete report highlighting areas for improvement followed by a set of recommendations.</p>
                    <p>Once the assessment was completed, we put together wireframes with our recommended improvements that would translate across a variety of devices and screen types - but most importantly addressed the needs of all user segments.</p>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/mop/CaseStudy-ManofParts-Analysis.jpg'} alt='Man of Parts' />
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/mop/CaseStudy-ManofParts-Wireframes.jpg'} alt='Man of Parts' />
                </div>

                <div className='clear md'></div>

                <img src={process.env.PUBLIC_URL + '/images/work/mop/CaseStudy-ManofParts-Mockup-scaled.jpg'} className="block full" alt='Man of Parts' />
            </section>

            <section className='section dark'>
                <div className='content'>
                    <div className='columns centerV wrap'>
                        <div className='col one_half'>
                        <img src={process.env.PUBLIC_URL + '/images/kramdar-featured.jpg'} className="border-radius" alt='The Summoning of Kramdar' />
                        </div>
                        <div className='col one_half'>
                            <span className='eyebrow yellow'>Next Case Study</span>
                            <h3>Applying every resource to make ideas real</h3>
                            <p>We have several passion projects which revolve around our interests and collective skill set. We are always looking for ways to utilize our creative minds in a way to showcase what we are capable of.</p>
                            <NavLink to="/the-summoning-of-kramdar" className="btn secondary case-study-alt">View Case Study</NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}