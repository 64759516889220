// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { NavLink } from "react-router-dom";

export default function Header() {
    const [scrollDirection, setScrollDirection] = useState('up');
    const [isVisible, setIsVisible] = useState(false); // Initially hidden
    const [lastScrollY, setLastScrollY] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false); // State to manage menu toggle

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const handleLinkClick = () => {
        setMenuOpen(false); // Close the menu
    };

    useEffect(() => {
        // Manage adding/removing 'no-scroll' class on body when menuOpen changes
        if (menuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup function to remove 'no-scroll' class if the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [menuOpen]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > 200) {
                if (currentScrollY > lastScrollY && scrollDirection !== 'down') {
                    setScrollDirection('down');
                    setIsVisible(false);
                } else if (currentScrollY < lastScrollY && scrollDirection !== 'up') {
                    setScrollDirection('up');
                    setIsVisible(true);
                }
            } else {
                setIsVisible(false);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, scrollDirection]);

    return (
        <header className={`${isVisible ? 'visible' : 'hidden'} ${menuOpen ? 'expand' : ''}`}>
            <div className={`${menuOpen ? 'hamburger active' : 'hamburger'}`} onClick={toggleMenu}>
                <span className={menuOpen ? 'line top active' : 'line top'}></span>
                <span className={menuOpen ? 'line middle active' : 'line middle'}></span>
                <span className={menuOpen ? 'line bottom active' : 'line bottom'}></span>
            </div>

            <img src={process.env.PUBLIC_URL + '/images/logo-white.svg'} className={`${menuOpen ? 'logo-mark active' : 'logo-mark'}`} alt='' />

            <nav>
                <ul>
                    <li><Link to="work" href="#work" spy={true} smooth={true} duration={500} rel="nofollow" onClick={handleLinkClick}>Work</Link></li>
                    <li><Link to="about" href="#about" spy={true} smooth={true} duration={500} rel="nofollow" onClick={handleLinkClick}>About</Link></li>
                    <li><Link to="services" href="#services" spy={true} smooth={true} duration={500} rel="nofollow" onClick={handleLinkClick}>Services</Link></li>
                    <li><Link to="faqs" href="#faqs" spy={true} smooth={true} duration={500} rel="nofollow" onClick={handleLinkClick}>FAQs</Link></li>
                    <li><NavLink to="/shop" onClick={handleLinkClick}>Shop</NavLink></li>
                    <li><Link to="contact" href='#contact' className="btn secondary contact" spy={true} smooth={true} duration={500} rel="nofollow" onClick={handleLinkClick}>Contact</Link></li>
                </ul>
            </nav>
        </header>
    );
}
