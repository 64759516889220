// src/sections/Contact.js

import React from 'react';

export default function Contact() {
    return(
        <section className='hero contact' id='contact'>            
            <div className='inner'>
                <div className='container'>
                    <span className='eyebrow'>Contact us</span>
                    <h2>Let's bring your next project to life!</h2>
                    <p>Send us a message to get things started, and someone from our team will reach out via email shortly.</p>
                    <p>We prioritize every detail—all communications will be handled through email to ensure nothing is overlooked.</p>
                    <a href='mailto:info@goodlookinkids.com' className='btn tertiary block large' target='_blank' rel="noreferrer">Email us at info@goodlookinkids.com</a>
                    <ul className='social'>
                        <li><a href='https://dribbble.com/lezus' target='_blank' rel='noreferrer'><img src={process.env.PUBLIC_URL + '/images/dribbble.svg'} alt='Dribbble' /></a></li>
                        <li><a href='https://glklee.medium.com/' target='_blank' rel='noreferrer'><img src={process.env.PUBLIC_URL + '/images/medium.svg'} alt='Medium' /></a></li>
                        <li><a href='https://www.instagram.com/glkinc/' target='_blank' rel='noreferrer'><img src={process.env.PUBLIC_URL + '/images/instagram.svg'} alt='Instagram' /></a></li>
                        <li><a href='https://www.linkedin.com/company/good-lookin-kids-inc-' target='_blank' rel='noreferrer'><img src={process.env.PUBLIC_URL + '/images/linkedin.svg'} alt='LinkedIn' /></a></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}