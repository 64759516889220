// src/pages/WeekendAtTheCottage.js

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Close from '../components/Close';

export default function WeekendAtTheCottage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Close />
            <section className='hero case-study'>            
                <img src={process.env.PUBLIC_URL + '/images/work/watc/CaseStudy-WATC-hero.jpg'} alt='Weekend at the Cottage' />
            </section>

            <section className='section'>
                <div className='narrow'>
                    <span className='eyebrow'>Case Study</span>
                    <h2>Weekend at the Cottage</h2>
                    <p className='XLarge true-blue'>In our minds every day can, and should, feel like it's a Weekend at the Cottage!</p>
                    <p>- <strong>Nike Manjovich,</strong> Founder of Weekend at the Cottage</p>
                    <p>A platform for cottage enthusiasts to share their favourite food and  drink recipes along with tips and ideas about life in cottage country.</p>
                </div>
            </section>

            <section className='section light-blue'>
                <div className='content alt'>
                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>The Challenge</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>Weekend at the Cottage has an extensive library of recipes and lifestyle posts. As time passed from the initial launch of the website, technologies have changed and new features had been added, all working within the existing framework. It was time to create a brand new experience without losing users and credibility that has been established over the past 5+ years.</p>
                        </div>
                    </div>

                    <div className='clear sm'></div>

                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>Our Approach</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>For the redesign/rebuild of WATC, we first identified who the users are, and what their goals are on the website, in addition to the overarching business goals. Once we established these two things, we created a strategy to enhance the overall experience of the user while simultaneously creating a framework that generates revenue. We achieved this by creating multiple navigational tools for the user as well as establishing a variety of advertising properties that were non-invasive to the overall user experience.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section no-bottom'>
                <div className='narrow'>
                    <span className='eyebrow dark'>Overview</span>
                    <p className='XLarge true-blue'>Weekend at the Cottage is a food and lifestyle blog centered around cottage living. They offer a ton of premium recipes, and cottage tips for like-minded individuals who love spending time at their cottage.</p>
                    <p>We first developed a website for WATC back in 2013. Since then their user base and overall traffic to the website has significantly increased. Our goal with bringing WATC into 2020 was to develop a fresh new look that was user-centric while simultaneously creating a strategy to generate advertising revenue.</p>
                    <a href='https://weekendatthecottage.com' target='_blank' className="btn external" rel="noreferrer">Visit Weekend at the Cottage</a>
                </div>

                <div className='clear xl'></div>

                <div className='content'>
                    <div className='columns'>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/watc/WATC-FeaturedRecipe.jpg'} alt='Weekend at the Cottage' />
                        </div>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/watc/WATC-Grid.jpg'} alt='Weekend at the Cottage' />
                        </div>
                    </div>
                </div>

                <div className='clear md'></div>

                <div className='narrow'>
                    <h4>Making the content accessible</h4>
                    <p>After doing some research and having discussions with potential users, a common complaint about recipe blogs is the amount of effort required to get to the actual recipe. Nik, the founder of Weekend of the Cottage does an incredible job of writing enticing instructions with peppered background details, but for some users they just want the details.</p>
                    <p>Our strategy was to streamline the process to have “quick-action” tools to easily jump directly to the recipe, as well as  tools to print out recipe sheets that have been simplified. To meet the revenue goals of the website, we used creative techniques to keep ad space visible to users even if they skip through the content.</p>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/watc/CaseStudy-WATC-UserFlow.jpg'} alt='Weekend at the Cottage' />
                </div>

                <div className='clear md'></div>

                <div className='narrow'>
                    <h4>Adaptive Design</h4>
                    <p>Mobile-first design is nothing new. The idea that you should design a website to work first on a mobile device brought light to the concept of responsive design. Responsive design is when containers scale, columns stack, and menus collapse. This ensures that your designs work across all screen sizes.</p>
                    <p>Where adaptive design comes into play is when objects are added or removed, plus layouts change in an unorthodox non-responsive way. Adaptive design doesn't simply move things to fit on a smaller screen, it creates a layout that is suited for the device. We took full advantage of this technique when designing and developing WATC.</p>
                    <p>Utilizing high-yielded UX techniques from popular apps we provided an app-like experience for mobile users via touch interactions and dynamic grids.</p>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/watc/WATC-touch-example.gif'} alt='Weekend at the Cottage' />
                </div>

                <div className='clear md'></div>

                <div className='narrow'>
                    <h4>Beyond the traditional website</h4>
                    <p>Lastly, as part of our strategy to improve the overall reach of the  website we looked at modern technologies and future applications. While  the website is fully optimized for search engine crawlers (with the help of WATC's incredibly written content), we wanted to go even further. We looked at how users access the type of content that WATC would be producing. One often overlooked access point is through Google Assistant. With the rise of smart home tech, we were able to easily use the framework that we built to have the meta content ready to be read by a variety of platforms, which means the recipes are read aloud through Google Home or Alexa.</p>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/watc/google-recipes.jpg'} alt='Weekend at the Cottage' />
                </div>

                <div className='clear lg'></div>

                <img src={process.env.PUBLIC_URL + '/images/work/watc/WATC-Footer.jpg'} className="block full" alt='Weekend at the Cottage' />
            </section>

            <section className='section dark'>
                <div className='content'>
                    <div className='columns centerV wrap'>
                        <div className='col one_half'>
                        <img src={process.env.PUBLIC_URL + '/images/CaseStudy-YellowHouseEvents.jpg'} className="border-radius" alt='Weekend at the Cottage' />
                        </div>
                        <div className='col one_half'>
                            <span className='eyebrow yellow'>Next Case Study</span>
                            <h3>Tailored solutions for unique challenges</h3>
                            <p>After successfully completing YHE's internal design needs, the work keeps coming! GLK and YHE have teamed up for a variety of graphic and web design projects for events for clients including Amazon, Instagram, Facebook and Intuit.</p>
                            <NavLink to="/yellow-house-events" className="btn secondary case-study-alt">View Case Study</NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}