// src/components/HeaderSimple.js

import React from 'react';
import { Link } from 'react-scroll';
import { NavLink } from "react-router-dom";

export default function HeaderSimple() {
    return(
        <>
            <nav>
                <ul>
                    <li>
                        <Link
                            to="work"
                            href="#work"
                            spy={true}
                            smooth={true}
                            duration={500}
                            rel="nofollow"
                        >
                        Work
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="about"
                            href="#about"
                            spy={true}
                            smooth={true}
                            duration={500}
                            rel="nofollow"
                        >
                        About
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="services"
                            href="#services"
                            spy={true}
                            smooth={true}
                            duration={500}
                            rel="nofollow"
                        >
                        Services
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="faqs"
                            href="#faqs"
                            spy={true}
                            smooth={true}
                            duration={500}
                            rel="nofollow"
                        >
                        FAQs
                        </Link>
                    </li>
                    <li>
                        <NavLink to="/shop">Shop</NavLink>
                    </li>
                    <li>
                        <Link
                            to="contact"
                            href="#contact"
                            spy={true}
                            smooth={true}
                            duration={500}
                            rel="nofollow"
                        >
                        Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}