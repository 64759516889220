// src/sections/Faq.js

import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const Faq = () => {
    const [openItem, setOpenItem] = useState(null);

    const toggleAccordion = (item) => {
        setOpenItem(openItem === item ? null : item);
    };

    return (
        <section className='section dark no-overflow relative' id='faqs'>
            <div className='content'>
                <span className='eyebrow center yellow'>Frequently Askes Questions</span>
                <h2 className='center'>Your Questions, Answered</h2>
                <div className='narrow center'>
                    <p className='large'>Here's a rundown of the most common questions we get.</p>
                </div>

                <div className='clear lg'></div>

                <div className="accordion">
                    <div className="accordion-item">
                        <button
                            className="accordion-header"
                            aria-expanded={openItem === 1}
                            onClick={() => toggleAccordion(1)}
                        >
                            <span className="accordion-label">Will you program my design?</span>
                            <span className="accordion-icon">{openItem === 1 ? '-' : '+'}</span>
                        </button>
                        <div className="accordion-content" style={{ maxHeight: openItem === 1 ? '100%' : '0' }}>
                            <p>In order to ensure the highest level of quality in the finished product, we only develop designs completed in-house by GLK. This guarantees proper UX, UI and development in the finished product. If you have a preliminary design we're happy to work as consultants to ensure all the checks are met and will provide revised plans, which (if approved), will then be ready for programming by our team.</p>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <button
                            className="accordion-header"
                            aria-expanded={openItem === 3}
                            onClick={() => toggleAccordion(3)}
                        >
                            <span className="accordion-label">Do you host websites?</span>
                            <span className="accordion-icon">{openItem === 3 ? '-' : '+'}</span>
                        </button>
                        <div className="accordion-content" style={{ maxHeight: openItem === 3 ? '100%' : '0' }}>
                            <p>We do not host websites but will recommend providers we've worked with in the past. If you're currently looking to purchase a domain or hosting package, we can help guide you on your selection. It's best to wait until we've had a discussion on your website goals prior to purchasing anything yourself in order to ensure compatibility with our development.</p>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <button
                            className="accordion-header"
                            aria-expanded={openItem === 4}
                            onClick={() => toggleAccordion(4)}
                        >
                            <span className="accordion-label">I need content for my website. Can you help?</span>
                            <span className="accordion-icon">{openItem === 4 ? '-' : '+'}</span>
                        </button>
                        <div className="accordion-content" style={{ maxHeight: openItem === 4 ? '100%' : '0' }}>
                            <p>We will provide creative for all UI (User Interface) layouts (which can include elements like icons and illustrations). If you need copy written, we do have affiliates to help, but a general outline of notes covering key topics is required to get started. We also work with professional photographers, animators and video production teams if required and will outline all costs in an estimate prior to starting a new project.</p>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <button
                            className="accordion-header"
                            aria-expanded={openItem === 6}
                            onClick={() => toggleAccordion(6)}
                        >
                            <span className="accordion-label">I currently have a website - will you update a part of it?</span>
                            <span className="accordion-icon">{openItem === 6 ? '-' : '+'}</span>
                        </button>
                        <div className="accordion-content" style={{ maxHeight: openItem === 6 ? '100%' : '0' }}>
                            <p>Every developer has their own way of programming a website. While it is possible to shoehorn in new templates, we do not offer this as a service as it can create problems in the future.</p>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <button
                            className="accordion-header"
                            aria-expanded={openItem === 11}
                            onClick={() => toggleAccordion(11)}
                        >
                            <span className="accordion-label">How are communications handled during a project?</span>
                            <span className="accordion-icon">{openItem === 11 ? '-' : '+'}</span>
                        </button>
                        <div className="accordion-content" style={{ maxHeight: openItem === 11 ? '100%' : '0' }}>
                            <p>To ensure nothing is missed, we prefer to communicate through email primarily. We will arrange for phone or video chats, if required, for walkthroughs and questions during approvals.</p>
                        </div>
                    </div>                    

                    <div className="accordion-item">
                        <button
                            className="accordion-header"
                            aria-expanded={openItem === 14}
                            onClick={() => toggleAccordion(14)}
                        >
                            <span className="accordion-label">What about the 'nitty gritty' fine print?</span>
                            <span className="accordion-icon">{openItem === 14 ? '-' : '+'}</span>
                        </button>
                        <div className="accordion-content" style={{ maxHeight: openItem === 14 ? '100%' : '0' }}>
                            <p>Check out our <NavLink to="/terms-of-service">Terms of Service</NavLink> for full details.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='flair-blue'></div>
            <div className='flair-yellow'></div>
            <div className='flair-red'></div>
        </section>
    );
};

export default Faq;
