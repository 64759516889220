// src/pages/Shop.js

import React, { useEffect } from 'react';

import Close from '../components/Close';

export default function Shop() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Close />
            <section className='hero shop'>            
                <div className='copy'>
                    <span className='sticker'>Closed</span>
                    <h2 className='large'>The GLK Shop is currently closed</h2>
                    <p>However! If you are looking to purchase any of our previously released products. Please inquire at:</p>
                    <a href='mailto:info@goodlookinkids.com' className='btn tertiary block large contact-alt' target='_blank' rel="noreferrer">info@goodlookinkids.com</a>
                </div>

                <div className='content'>
                    <div className="grid third shop">
                        <img src={process.env.PUBLIC_URL + '/images/products/kramdar.jpg'} alt='The Summoning of Kramdar' />
                        <img src={process.env.PUBLIC_URL + '/images/products/kramdar-pin.jpg'} alt='The Summoning of Kramdar Pin' />
                        <img src={process.env.PUBLIC_URL + '/images/products/stickers.jpg'} alt='GLK Stickers' />
                        <img src={process.env.PUBLIC_URL + '/images/products/drag-pin-1.jpg'} alt='Tucked Treasures' />
                        <img src={process.env.PUBLIC_URL + '/images/products/drag-pin-2.jpg'} alt='Tucked Treasures' />
                        <img src={process.env.PUBLIC_URL + '/images/products/drag-pin-3.jpg'} alt='Tucked Treasures' />
                    </div>
                </div>
            </section>
        </>
    );
}