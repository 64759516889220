// src/components/Footer.js

import React from 'react';
import { NavLink } from "react-router-dom";

export default function Footer({ isCaseStudy, isShop }) {
    const footerClass = `${isCaseStudy ? "alt" : ""} ${isShop ? "hide" : ""}`;
  
    return (
        <footer className={footerClass.trim()}>
            <div>
                <NavLink to="/"><img src={process.env.PUBLIC_URL + '/images/footer-logo.svg'} alt='' className='footer-logo' /></NavLink>
            </div>
            <div className='center'>
                <p>Designing the Future of Brands,<br /> Experiences, and Stories</p>
            </div>
            <div>
               <p className='small'>&copy;2025 All rights reserved.<br />
               <NavLink to="/terms-of-service">Terms of Service</NavLink></p> 
            </div>
        </footer>
    );
}