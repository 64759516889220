import React, { useEffect, useRef } from 'react';

export default function About() {
    const galleryRef = useRef(null);

    useEffect(() => {
        const gallery = galleryRef.current;
        const scrollSpeed = 2; // Adjust this value to control the speed

        // Duplicate gallery items for infinite scroll effect
        gallery.innerHTML += gallery.innerHTML;

        let scrollPosition = 0;

        // Scrolling loop
        function scrollGallery() {
            scrollPosition += scrollSpeed;
            if (scrollPosition >= gallery.scrollHeight / 2) {
                scrollPosition = 0; // Reset to the start of the gallery
            }
            gallery.scrollTop = scrollPosition;
            requestAnimationFrame(scrollGallery);
        }

        // Start the scroll animation
        scrollGallery();

        return () => {
            // Clean up the effect if the component is unmounted
            cancelAnimationFrame(scrollGallery);
        };
    }, []);

    return (
        <>
            <section className='hero about' id='about'>            
                <div className='inner'>
                    <div className='columns'>
                        <div className='col half'>
                            <span className='eyebrow'>About Us</span>
                            <h2>Crafting Creativity in Any Medium</h2>
                            <p>GLK has evolved into a versatile creative powerhouse, founded over a decade ago by brothers and business partners Chris and Lee Travaglini. Renowned for crafting dynamic websites and interactive experiences, its expertise reaches far beyond the digital world. From bespoke visuals to immersive storytelling across a variety of media, the studio collaborates with clients in industries such as finance, entertainment, technology, communications, and professional services to bring creative visions to life.</p>
                            <img src={process.env.PUBLIC_URL + `/images/gallery/about-mobile.png`} className="hide-desktop" alt='' />
                        </div>
                        <div className='col half'>
                            <ul className="gallery" ref={galleryRef}>
                                {/* Render gallery items twice to allow infinite scroll */}
                                {Array(2)
                                    .fill(
                                        [
                                            'slide-1.png', 'slide-2.png', 'slide-3.png', 'slide-4.png',
                                            'slide-5.png', 'slide-6.png', 'slide-7.png', 'slide-8.png',
                                            'slide-9.png', 'slide-10.png', 'slide-11.png', 'slide-12.png',
                                            'slide-13.png', 'slide-14.png'
                                        ]
                                    )
                                    .flat()
                                    .map((slide, index) => (
                                        <li key={index}>
                                            <img src={process.env.PUBLIC_URL + `/images/gallery/${slide}`} alt='' />
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>

                    <div className='hero-flair-blue'></div>
                    <div className='hero-flair-yellow'></div>
                    <div className='hero-flair-red'></div>
                </div>
            </section>
        </>
    );
}