// src/pages/Home.js

import React from 'react';

// Components
import Header from "../components/Header";

// Sections
import Hero from "../sections/Hero";
import Work from "../sections/Work";
import About from "../sections/About";
import Services from "../sections/Services";
import Faq from "../sections/Faq";
import Contact from "../sections/Contact";

export default function Home() {
    return(
        <>
            <Header />
            <Hero />
            <Work />
            <About />
            <Services />
            <Faq />
            <Contact />
        </>
    );
}