// src/pages/TermsOfService.js

import React, { useEffect } from 'react';

import Close from '../components/Close';

export default function TermsOfService() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Close />
            <section className='section terms'>
                <div className='narrow'>
                    <h1>Terms of Service</h1>
                    <h3>Terms & Conditions</h3>
                    <p>The Design and Production Services described in the contract or invoice by Good Lookin Kids Inc. to the client are governed by the following terms and conditions. These terms and conditions include the final Scope of Work and timeline, any change orders set forth in writing and executed by Good Lookin Kids Inc. and the Client after the acceptance of the original Scope of Work. Changes to the Scope of Work may result in adjustments to the charges for the Project.</p>
                    <p>Good Lookin Kids Inc. rejects any additional or different terms or conditions proposed by Client unless otherwise agreed in writing.</p>
                    <div className='clear md'></div>
                    <h3>Payment</h3>
                    <p>Payment for Design and Production Services will be made as follows:</p>
                    <ul className='bullets'>
                    <li>50% of the Estimated Design and Production Fees are due upon acceptance of the proposal.</li>
                    <li>The remaining balance (including any and all expenses for vendors, service providers, specialists, or subcontractors engaged in accordance with the proposal (“Outside Expenses”) not paid in advance by Client) will be due upon delivery of the finished Project or agreed-upon completion date (whichever comes first).</li>
                    <li>Except for the portions of invoices that are disputed in good faith by the Client for not being in accordance with the terms and conditions of this Agreement, any amounts not paid when due shall accrue interest at the rate of 10% per month from the date due until paid.</li>
                    <li>Good Lookin Kids Inc. reserves the right to withhold delivery of all electronic and/or printed materials until the undisputed portion(s) of overdue invoices are paid.</li>
                    <li>For any ongoing projects, payment is due within 15 days of receiving the invoice. Invoices paid out longer than 30 days are subject to accrue interest at 10% per month from the date due until paid.</li>
                    <li>All design, maintenance, and development is charged at a rate of $150/hr.</li>
                    </ul>
                    <div className='clear md'></div>
                    <h3>Changes to Scope</h3>
                    <p>Revisions or author's alterations to the Scope of Work shall obligate the Client to additional fees and costs. These may include but are not limited to:</p>
                    <ul className='bullets'>
                    <li>Changes made to copy after the final copy has been submitted.</li>
                    <li>Changes made to the design once layouts, website design, or site map have been approved.</li>
                    <li>Extensive alterations.</li>
                    <li>A change in marketing objectives on the part of the Client.</li>
                    <li>New work requested by the Client after the execution of the Agreement.</li>
                    </ul>
                    <p>Deadlines are to be met as agreed upon at the start of the project. If milestone deadlines are missed due to client delays, Good Lookin Kids Inc. will grant no more than 14 days as a buffer before moving on to the next stages at which point additional fees may incur. If multiple delays push the agreed-upon completion date beyond 14 days, payment will be due at the end of the 14-day grace period. Interest may be charged if further delays occur.</p>
                    <p>All production costs are based on the assumption that copy will be provided electronically. Change orders will be prepared by Good Lookin Kids Inc. and provided to the Client outlining the changes to the Scope of Work, and any additional costs for those changes. The Client agrees to pay Good Lookin Kids Inc. additional fees and costs for said revisions or alterations at a rate to be determined on a project-to-project basis.</p>
                    <div className='clear md'></div>
                    <h3>Overtime / Rush Charges</h3>
                    <p>Estimates are based on normal and reasonable time schedules and may have to be revised to take into consideration any “rush” requests requiring overtime or weekends. Knowledge of the Client's deadline is essential to provide an accurate estimate of costs.</p>
                    <p>Good Lookin Kids Inc. overtime/rush fee incurred at the Client's request will be billed at a rate of $200/hr. The Client will also be responsible for additional charges imposed by outside suppliers, such as pre-press or printers, to meet Client's “rush” requests.</p>
                    <p>To the extent possible, Good Lookin Kids Inc. will advise Client of all situations that require overtime and/or rush charges, and the amount of additional compensation that will be charged to meet such overtime requirements or rush requests.</p>
                    <p>Rush or overtime fees may be incurred if the Client does not meet approval or content deadlines which have been established to meet the Client's desired schedule.</p>
                    <div className='clear md'></div>
                    <h3>Monthly Retainer Subscriptions</h3>
                    <p>We offer monthly subscription packages for maintenance and other small requests at discounted hourly rates. If you are not on a maintenance package, we have a 3-hour minimum at $120/hr for updates, consultation, review, etc. Contact us for subscription pricing.</p>
                    <p><strong>Terms of Monthly Retainer Subscription:</strong></p>
                    <ul className='bullets'>
                    <li>Unused hours cannot be transferred to the next month.</li>
                    <li>The hourly rate will remain the same for additional hours that go over the allotted amount per month.</li>
                    <li>The monthly subscription is prepaid at the beginning of each month to ensure time allotment.</li>
                    <li>A minimum 3-month commitment is required for all monthly subscriptions.</li>
                    <li>Update and maintenance requests will be made within a 24-hour window (pending size of request).</li>
                    <li>All requests need to be submitted via email to ensure requests are recorded and scheduled accordingly.</li>
                    <li>The monthly subscription can be used for:
                        <ul className='bullets'>
                        <li>Graphic Design</li>
                        <li>Website edits</li>
                        <li>Content populating</li>
                        <li>Social Media creative</li>
                        <li>Print material design</li>
                        <li>Illustration</li>
                        <li>Photo editing</li>
                        <li>Email marketing campaigns</li>
                        </ul>
                    </li>
                    <li>It cannot be used for:
                        <ul className='bullets'>
                        <li>New project builds (new projects will be individually assessed and quoted independently)</li>
                        </ul>
                    </li>
                    </ul>
                    <div className='clear md'></div>
                    <h3>Approval Process</h3>
                    <p>Once the client has approved each phase of the project (Wireframes, UI, Staging site prior to launch), any additional requests or changes will be counted as additional billable hours. This includes but is not restricted to structural changes, content edits, modification of functionality, etc.</p>
                    <p>Good Lookin Kids Inc. will do their best to complete the work to specifications during each of these checkpoints of the project, but it is the client's responsibility to notify Good Lookin Kids Inc. of any errors, changes or requests prior to providing approval at each phase of the project.</p>
                    <p>In regards to web design, upon launch of the website (approval to launch provided by client) the project is complete unless otherwise outlined and final payment is due.</p>
                    <div className='clear md'></div>
                    <h3>Storage of Completed Work</h3>
                    <p>Good Lookin Kids Inc. stores working files of completed work for up to 60 days after providing the completed work to the client. The client is responsible for ensuring that all final products provided by Good Lookin Kids Inc. are stored and backed up locally on the client-side. Website backups are stored for up to 60 days after launch (taken publicly live on client-side servers). Unless a retainer is negotiated, all future updates/maintenance will be charged on a per-project basis with a minimum of 3 hours per update. Good Lookin Kids Inc. does not offer any warranties or guarantees on supplied products that surpass the 60-day holding grace period.</p>
                    <div className='clear md'></div>
                    <h3>Ownership &amp; Usage Rights</h3>
                    <p>Once the client has made full payment for services, Good Lookin Kids Inc. will transfer the rights granted under this Agreement to the client. The client will then have exclusive and unlimited usage and reproduction rights to the final designs prepared for the client as part of the project. All working files are owned by Good Lookin Kids Inc. Except for the foregoing license, all right, title, and interest to all designs and artwork (whether draft or final versions) remain with Good Lookin Kids Inc. or its contractors or vendors, as applicable. This includes, but is not limited to, layouts, animations, and designs created by Good Lookin Kids Inc. or its contractors or vendors, computer disks containing such layouts, photography or illustration created by independent photographers or illustrators commissioned by Good Lookin Kids Inc., and photography or other images purchased by Good Lookin Kids Inc. from a stock agency on the client's behalf. Good Lookin Kids Inc. reserves the right to reproduce any and all designs created by Good Lookin Kids Inc. in print and electronic media for Good Lookin Kids Inc.'s promotional purposes for an unlimited period of time. In developing any brand marks, Good Lookin Kids Inc. will use reasonable commercial efforts, consistent with standards in the industry, to ensure that any such brand marks are original. Good Lookin Kids Inc.'s efforts shall not include a complete trademark clearance search. Should a higher level of assurance be required by the client, the services of a trademark search firm and intellectual property attorney should be retained by the client.</p>
                    <div className='clear md'></div>
                    <h3>Errors</h3>
                    <p>The client is responsible for proofreading and examining all work produced during the project. Therefore, the client is ultimately responsible for any typographical, spelling, grammatical, copy, photographic, illustrative, layout, or other errors discovered after printing or reproduction, or for any work or services performed by any party selected by the client. If the client determines that there are errors in the work produced during the project, the client shall notify Good Lookin Kids Inc. of any errors within 48 hours of the client's determination. Failure to promptly notify Good Lookin Kids Inc. shall constitute a waiver by the client of any claim arising out of such errors.</p>
                    <div className='clear md'></div>
                    <h3>Cancellation</h3>
                    <p>In the event that the client cancels this agreement prior to the completion of the project, within five (5) business days of such cancellation, the client shall pay (a) Good Lookin Kids Inc. for all work performed by Good Lookin Kids Inc. up to the date of termination, (b) for all contracted for outside expenses and commitments that have been incurred and cannot be cancelled, and (c) a cancellation fee equal to 20% of the remaining fees that would otherwise have been paid to Good Lookin Kids Inc. if the agreement were to have been fully performed.</p>
                </div>
            </section>
        </>
    );
}