// src/sections/Work.js

import React from 'react';
import { NavLink } from "react-router-dom";

export default function Work() {
    return(
        <section className='section' id='work'>            
            <div className='inner'>
               <div className='narrow center'>
                <span className='eyebrow'>Case Studies</span>
                <h2>Our Curated Work and Innovative Process</h2>
                <p className='large'>For over a decade, we've been designing and creating both tangible and interactive experiences that captivate and connect.</p>
               </div>

                <div className='clear xl'></div>

                <div className='z-grid'>
                    <div className='z-grid-container'>
                        <div className='image'>
                            <img src={process.env.PUBLIC_URL + '/images/CaseStudy-WATC.jpg'} alt='Weekend at the Cottage' />
                        </div>
                        <div className='details'>
                            <span className='eyebrow'>UX/UI/Strategy</span>
                            <h3>Designing targeted strategies to achieve business goals</h3>
                            <p>We first developed a website for WATC back in 2013. Since then their  user base and overall traffic to the website has significantly  increased. Our goal with bringing WATC into 2020 was to develop a fresh  new look that was user-centric while simultaneously creating a strategy  to generate advertising revenue.</p>
                            <NavLink to="/weekend-at-the-cottage" className="btn case-study">View case study</NavLink>
                        </div>
                    </div>
                    <div className='z-grid-container'>
                        <div className='image'>
                            <img src={process.env.PUBLIC_URL + '/images/CaseStudy-YellowHouseEvents.jpg'} alt='Yellow House Events' />
                        </div>
                        <div className='details'>
                            <span className='eyebrow'>Graphic &amp; Web Design</span>
                            <h3>Tailored solutions for unique challenges</h3>
                            <p>After successfully completing YHE's internal design needs, the work keeps coming! GLK and YHE have teamed up for a variety of graphic and web design projects for events for clients including Amazon, Instagram, Facebook and Intuit.</p>
                            <NavLink to="/yellow-house-events" className="btn case-study">View case study</NavLink>
                        </div>
                    </div>
                    <div className='z-grid-container'>
                        <div className='image'>
                            <img src={process.env.PUBLIC_URL + '/images/CaseStudy-ManOfParts-Featured.jpg'} alt='Man of Parts' />
                        </div>
                        <div className='details'>
                            <span className='eyebrow'>UX / UI Design</span>
                            <h3>Blending simplicity and style for user-friendly experiences</h3>
                            <p>Man of Parts was looking to enhance its online user experience while upholding its brand's high-end aesthetic. By understanding who their online users are, we're in the process of helping them build something great!</p>
                            <NavLink to="/man-of-parts" className="btn case-study">View case study</NavLink>
                        </div>
                    </div>
                    <div className='z-grid-container'>
                        <div className='image'>
                            <img src={process.env.PUBLIC_URL + '/images/kramdar-featured.jpg'} alt='The Summoning of Kramdar' />
                        </div>
                        <div className='details'>
                            <span className='eyebrow'>Product Design / Marketing</span>
                            <h3>Applying every resource to make ideas real</h3>
                            <p>We have several passion projects which revolve around our interests and collective skill set. We are always looking for ways to utilize our creative minds in a way to showcase what we are capable of.</p>
                            <NavLink to="/the-summoning-of-kramdar" className="btn case-study">View case study</NavLink>
                        </div>
                    </div>
                </div>

                <div className='center'>
                    <h2>More of Our Digital Work</h2>
                </div>

                <div className='clear sm'></div>

                <div className='work-grid'>
                    <a href='https://originate.ca/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Originate</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/originate-thumb.jpg'} alt='Originate' />
                    </a>
                    <a href='https://redwheels.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Redwheels</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/redwheels-thumb.jpg'} alt='Redwheels' />
                    </a>
                    <a href='https://sickkidscmh.ca/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>SickKids CCMH</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/ccmh-thumb.jpg'} alt='SickKids CCMH' />
                    </a>
                    <a href='https://2mtn.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Second Mountain</p>
                            <p><span>Launch Website</span></p>
                        </div>
                       <img src={process.env.PUBLIC_URL + '/images/work/2mtn-thumb.jpg'} alt='Secound Mountain' />
                    </a>
                    <a href='https://partspalace.ca/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Parts Palace</p>
                            <p><span>Launch Website</span></p>
                        </div>
                       <img src={process.env.PUBLIC_URL + '/images/work/pp-thumb.jpg'} alt='Parts Palace' />
                    </a>
                    <a href='https://robertcameron.ca/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Robert Cameron</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/rc-thumb.jpg'} alt='Robert Cameron' />
                    </a>
                    <a href='https://osheasirishsnug.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Oshea's Irish Snug</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/osheas-thumb.jpg'} alt='Osheas Irish Snug' />
                    </a>
                    <a href='https://onetigernorth.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>One Tiger North</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/otn-thumb.jpg'} alt='One Tiger North' />
                    </a>
                    <a href='https://csc.ca/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Canadian Society of Cinematographers</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/csc-thumb.jpg'} alt='Canadian Society of Cinematographers' />
                    </a>
                    <a href='https://alexandershowcasetheatre.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Alexander Showcase Theatre</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/ast-thumb.jpg'} alt='Alexander Showcase Theatre' />
                    </a>
                    <a href='https://viviimports.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Vivi Imports</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/vivi-thumb.jpg'} alt='Vivi Imports' />
                    </a>
                    <a href='https://level19.com/' target='_blank' rel='noreferrer'>
                        <div className='overlay'>
                            <p>Level 19</p>
                            <p><span>Launch Website</span></p>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/work/level-19-thumb.jpg'} alt='Level 19' />
                    </a>
                </div>

            </div>
        </section>
    );
}