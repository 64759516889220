// src/pages/TheSummoningOfKramdar.js

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Close from '../components/Close';

export default function TheSummoningOfKramdar() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Close />
            <section className='hero case-study'>            
                <img src={process.env.PUBLIC_URL + '/images/work/kramdar/CaseStudy-Kramdar-Top.jpg'} alt='The Summoning of Kramdar' />
            </section>

            <section className='section'>
                <div className='narrow'>
                    <span className='eyebrow'>Case Study</span>
                    <h2>The Summoning of Kramdar</h2>
                    <p className='XLarge true-blue'>When hell is on earth and the plagues are a plenty the only salvation is Kramdar.</p>
                    <p>Make offerings when the moon is highest in the sky to satisfy Kramdar's appetite - but be warned; If you have nothing to give, Kramdar will take a piece of your life force until nothing is left.</p>
                </div>
            </section>

            <section className='section light-blue'>
                <div className='content alt'>
                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>The Challenge</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>This time it's personal. As avid gamers ourselves (in both videogames and tabletop) we wanted to create something fun and uniquely our own. With our combined skillsets the bar was set high and we were ready to rise to the occasion.</p>
                        </div>
                    </div>

                    <div className='clear sm'></div>

                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>Our Approach</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>Through many prototype iterations and playing the game with friends and family (repeatedly) and utilizing some new 3D sculpting skills, we were able to turn a dream into a reality.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className='clear xl'></div>

            <div className='content'>
                <img src={process.env.PUBLIC_URL + '/images/work/kramdar/CaseStudy-Kramdar-Booklet.jpg'} alt='The Summoning of Kramdar' />
            </div>

            <section className='section no-bottom'>
                <div className='narrow'>
                    <span className='eyebrow dark'>Overview</span>
                    <p className='XLarge true-blue'>Unique gameplay and concept with a marketing campaign to back it.</p>
                    <p>The Summoning of Kramdar is a 2-4 player tabletop board game that we developed, and launched on Kickstarter. Over three years we developed a fully functioning game that was tested, refined and manufactured overseas.</p>
                    <a href='https://glkinc.medium.com/the-summoning-of-kramdar-4695adb8e179' target='_blank' className="btn external" rel="noreferrer">Read the full case study on Medium</a>
                </div>

                <div className='clear xl'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/kramdar/CaseStudy-Kramdar-Gamepiece.jpg'} alt='The Summoning of Kramdar' />
                </div>

                <div className='clear md'></div>

                <div className='narrow'>
                    <h4>The long road to get here</h4>
                    <p className='XLarge true-blue'>Nothing happens overnight, especially with a passion project. We've been summoning Kramdar for years and his time to reign will be upon us soon.</p>
                    <p>What started as a combination of childhood nightmares memories, late-night TV binging and a personal hobby has grown into a fully realized product. The road to get here was long and laborious. From paper prototypes and about 100+ games of trial and error, we landed with a set of rules that are fast-paced and allow for a few twists with each new game play.</p>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/kramdar/CaseStudy-Kramdar-Box.jpg'} alt='The Summoning of Kramdar' />
                </div>

                <div className='clear xl'></div>

                <div className='narrow'>
                    <h4>The planets started to align</h4>
                    <p>Table-top and card games were rising in popularity, as was Lee's interest and expertise in the subject. Chris had just graduated from Creature and Character Design at CMU and we had a 3D printer waiting to be utilized.</p>
                    <p>We had the whole thing planned, illustrated, mocked-up and ready for production. With the help of a business advisor and friend, we connected with a production company overseas to work out costs. We quickly discovered that our original vision to include tangible, 3D pieces with the game would add significant cost and production time. The compromise was to produce a 'Standard Edition' to generate initial sales that would include cut-outs and stands in place of the 3D player pieces.</p>
                    <p>In 2020, we launched our offical Kickstarter campaign which was successfully funded. We created a commerical spot, sent out PR kits and ran a social media campaign. Here is what some of that looked like.</p>
                </div>

                <div className='clear md'></div>
                
                <div className='content'>
                    <div className="video-container">
                        <iframe
                            src="https://player.vimeo.com/video/548444727"
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video"
                        ></iframe>
                    </div>
                </div>


                <div className='clear xl'></div>

                <img src={process.env.PUBLIC_URL + '/images/work/kramdar/CaseStudy-Kramdar-Footer.jpg'} className="block full" alt='The Summoning of Kramdar' />
            </section>

            <section className='section dark'>
                <div className='content'>
                    <div className='columns centerV wrap'>
                        <div className='col one_half'>
                        <img src={process.env.PUBLIC_URL + '/images/CaseStudy-WATC.jpg'} className="border-radius" alt='Weekend at the Cottage' />
                        </div>
                        <div className='col one_half'>
                            <span className='eyebrow yellow'>Next Case Study</span>
                            <h3>Designing targeted strategies to achieve business goals</h3>
                            <p>We first developed a website for WATC back in 2013. Since then their user base and overall traffic to the website has significantly increased. Our goal with bringing WATC into 2020 was to develop a fresh new look that was user-centric while simultaneously creating a strategy to generate advertising revenue.</p>
                            <NavLink to="/weekend-at-the-cottage" className="btn secondary case-study-alt">View Case Study</NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}