import React, { useEffect } from "react";
import './App.css';
import './Responsive.css';

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Components
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import WeekendAtTheCottage from "./pages/WeekendAtTheCottage";
import YellowHouseEvents from "./pages/YellowHouseEvents";
import ManOfParts from "./pages/ManOfParts";
import TheSummoningOfKramdar from "./pages/TheSummoningOfKramdar";
import TermsOfService from "./pages/TermsOfService";
import Shop from "./pages/Shop";

// Scroll to the top of the page on route change
function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function AnimatedRoutes({ setIsCaseStudy, setIsShop }) {
  const location = useLocation();

  // Detect if the current path is a case study
  useEffect(() => {
    const caseStudyPaths = [
      "/weekend-at-the-cottage",
      "/yellow-house-events",
      "/man-of-parts",
      "/the-summoning-of-kramdar",
      "/terms-of-service"
    ];

    // Set `setIsCaseStudy` to true if any of the case study paths match the current location
    setIsCaseStudy(caseStudyPaths.includes(location.pathname));
    // Set `isShop` to true if the path is "/shop"
    setIsShop(location.pathname === "/shop");
  }, [location, setIsCaseStudy, setIsShop]);

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="page"
        timeout={300}
      >
        <div className="page-wrapper">
          <ScrollToTop />
          <Routes location={location}>
            <Route path="/" element={<Home />} />

            {/* CASE STUDIES */}
            <Route path="/weekend-at-the-cottage" element={<WeekendAtTheCottage />} />
            <Route path="/yellow-house-events" element={<YellowHouseEvents />} />
            <Route path="/man-of-parts" element={<ManOfParts />} />
            <Route path="/the-summoning-of-kramdar" element={<TheSummoningOfKramdar />} />

            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/shop" element={<Shop />} />
          </Routes>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default function App() {
  const [isCaseStudy, setIsCaseStudy] = React.useState(false);
  const [isShop, setIsShop] = React.useState(false);

  return (
    <Router>
      <>
        <AnimatedRoutes setIsCaseStudy={setIsCaseStudy} setIsShop={setIsShop} />
        <Footer isCaseStudy={isCaseStudy} isShop={isShop} />
      </>
    </Router>
  );
}