// src/sections/Services.js

import React from 'react';

export default function Services() {
    return(
        <section className='section no-bottom' id='services'>            
            <div className='inner'>
               <div className='narrow center'>
                    <span className='eyebrow'>Our Services</span>
                    <h2>Experts in crafting exceptional experiences</h2>
                    <p className='large'>Finding the balance between form, function, and user experience, while staying true to core design principles.</p>
               </div>

               <div className='clear xl'></div>

                <div className='content'>
                    <div className='grid half'>
                        <div className='service'>
                            <div className='service-thumb red'>
                                <img src={process.env.PUBLIC_URL + '/images/stickers/sticker-ux.png'} alt='UX' />
                            </div>
                            <h3>UX / UI Design &amp; Consultation</h3>
                            <p>Sometimes it's hard to have an unbiased perspective on your project. Let us do a deep dive review to identify any pain points and provide optimal and catered solutions to engage more users.</p>
                            <div className='columns'>
                                <ul>
                                    <li>Research &amp; Discovery</li>
                                    <li>Layout &amp; Flow Designs</li>
                                    <li>Design Systems</li>
                                </ul>
                                <ul>
                                    <li>Information Architecture</li>
                                    <li>Analysis Report</li>
                                    <li>UX Consultation</li>
                                </ul>
                            </div>
                        </div>
                        <div className='service'>
                            <div className='service-thumb yellow'>
                                <img src={process.env.PUBLIC_URL + '/images/stickers/sticker-design.png'} alt='Design' />
                            </div>
                            <h3>Graphic Design &amp; Creative Services</h3>
                            <p>With backgrounds in print, industiral and web design, we have the skillsets and experience to approach each medium uniquely and appropriately.</p>
                            <div className='columns'>
                                <ul>
                                    <li>Illustration</li>
                                    <li>Graphic Design</li>
                                    <li>Compositing</li>
                                </ul>
                                <ul>
                                    <li>Product Design</li>
                                    <li>Cross Media Design</li>
                                    <li>Fabrication</li>
                                </ul>
                            </div>
                        </div>
                        <div className='service'>
                            <div className='service-thumb cobalt'>
                                <img src={process.env.PUBLIC_URL + '/images/stickers/sticker-motion.png'} alt='Motion' />
                            </div>
                            <h3>Motion Design</h3>
                            <p>We have a history of producing various motion products including corporate videos, music videos, informational animations and more.</p>
                            <div className='columns'>
                                <ul>
                                    <li>Motion Design</li>
                                    <li>Editing</li>
                                    <li>Compositing</li>
                                </ul>
                                <ul>
                                    <li>Drone Services</li>
                                    <li>Animation</li>
                                    <li>Directing &amp; Production</li>
                                </ul>
                            </div>
                        </div>
                        <div className='service'>
                            <div className='service-thumb blue'>
                                <img src={process.env.PUBLIC_URL + '/images/stickers/sticker-brand.png'} alt='Brand' />
                            </div>
                            <h3>Branding</h3>
                            <p>Brand cohesion and high-calibre aesthetics can transform your business into something great. We utilize design systems and brand consistency to elevate your overall identity.</p>
                            <div className='columns'>
                                <ul>
                                    <li>Logo Development</li>
                                    <li>Usage Guidelines</li>
                                    <li>Brand Strategy</li>
                                </ul>
                                <ul>
                                    <li>Brand Kit</li>
                                    <li>Stationary Templates</li>
                                    <li>Social Media Assets</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}