// src/components/Close.js

import React from 'react';
import { NavLink } from "react-router-dom";

export default function Close() {
    return(
        <NavLink 
        to="/"
        className="btn sticky"
        >
            Close
        </NavLink>
    );
}