// src/pages/YellowHouseEvents.js

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Close from '../components/Close';

export default function YellowHouseEvents() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Close />
            <section className='hero case-study'>            
                <img src={process.env.PUBLIC_URL + '/images/work/yhe/GLK-Interactive-Feature-YHE.jpg'} alt='Yellow House Events' />
            </section>

            <section className='section'>
                <div className='narrow'>
                    <span className='eyebrow'>Case Study</span>
                    <h2>Yellow House Events</h2>
                    <p className='XLarge true-blue'>If you want to run the same old boring event, this is likely not the agency for you. Yellow House Events specializes in the unboring.</p>
                    <p>They refresh, redesign and rethink events and activations to ensure talk-worthy, share-worthy experiences that resonate with modern audiences.</p>
                </div>
            </section>

            <section className='section light-blue'>
                <div className='content alt'>
                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>The Challenge</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>GLK's first project with YHE was a complete website refresh - bringing brand cohesion across all digital collateral. From there we were brought in to assist with a multitude of projects - from designing transformative spaces for event activations to creating a web app for one of Canada's largest conferences.</p>
                        </div>
                    </div>

                    <div className='clear sm'></div>

                    <div className='columns wrap'>
                        <div className='col one_fourth'>
                            <h4>Our Approach</h4>
                        </div>
                        <div className='col three_fourth'>
                            <p>For YHE's website, we worked to improve the User Experience, create an adaptive site for multiple devices and bring design cohesion across their branding collateral. Matching the personalities of the YHE team, as well as reflecting their unique office space were key in uniting YHE's vision. On top of our original project, below you'll find a variety of creative for multiple events and activations GLK has assisted YHE with. Clients include: Instagram, Facebook, Amazon, OTPP and Intuit.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section no-bottom'>
                <div className='narrow'>
                    <span className='eyebrow dark'>Overview</span>
                    <p className='XLarge true-blue'>Yellow House Events create an environment that inspires attendees to think, celebrate, reflect and share. They bring in best-in-class vendors, build a plan to reach the right people and we will ensure that it was worthy of their time.</p>
                    <p>Our first impression when visiting YHE's office was WOW! This is exactly the reaction a company filled with talented people and A-List clients was looking for. Located in the heart of the Distillery District in downtown Toronto, YHE is a destination in itself.</p>
                    <a href='https://yellowhouseevents.com' target='_blank' className="btn external" rel="noreferrer">Visit Yellow House Events</a>
                </div>

                <div className='clear xl'></div>

                <div className='content'>
                    <div className='columns'>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/yhe/yellowHouse.jpg'} alt='Yellow House Events' />
                        </div>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/yhe/YellowHouseEvents-Office.jpg'} alt='Yellow House Events' />
                        </div>
                    </div>
                </div>

                <div className='clear md'></div>

                <div className='narrow'>
                    <h4>Refining the brand</h4>
                    <p className='XLarge true-blue'>“We live in an experience economy and time is life's currency. Give people a shareable experience worthy of their time.“
                    <br /><span className='small'>- Grail Noble, CEO & Founder</span></p>
                    <p>Through a series of base concepts, we were quickly able to nail down a direction and really express what YHE is all about. A flexible, adaptive website that represents the energy behind its people was key. The finished product is bright, bold and unboring.</p>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Process.jpg'} alt='Yellow House Events' />
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Work1.jpg'} alt='Yellow House Events' />
                </div>

                <div className='clear xl'></div>

                <div className='narrow'>
                    <h4>Big Ideas. Well executed</h4>
                    <p>Post-launch of the YHE website, GLK was brought back in to assist with a variety of design-related projects. Working with printers, set-designers, event-planners, etc. GLK was able to bring YHE's ideas to life.</p>
                    <p>A few projects to highlight: Event space mock-ups and printed collateral for Instagram's SEE MORE Event held in Toronto; Event registration and a Web App for the Ontario Teachers' Pension Plan; Signage & print collateral for Facebook events; Presentation material for Intuit's Quickbooks CONNECT conference.</p>
                </div>

                <div className='clear xl'></div>

                <div className='content'>
                    <div className='columns'>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Instagram2.jpg'} alt='Yellow House Events' />
                        </div>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Instagram3.jpg'} alt='Yellow House Events' />
                        </div>
                    </div>
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Instagram.jpg'} alt='Yellow House Events' />
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-OTPP1.jpg'} alt='Yellow House Events' />
                </div>

                <div className='clear md'></div>

                <div className='content'>
                    <div className='columns'>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Facebook.jpg'} alt='Yellow House Events' />
                        </div>
                        <div className='col one_half'>
                            <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Facebook1.jpg'} alt='Yellow House Events' />
                        </div>
                    </div>
                </div>

                <div className='clear md'></div>

                <img src={process.env.PUBLIC_URL + '/images/work/yhe/CaseStudy-YellowHouseEvents-Footer.jpg'} className="block full" alt='Yellow House Events' />
            </section>

            <section className='section dark'>
                <div className='content'>
                    <div className='columns centerV wrap'>
                        <div className='col one_half'>
                        <img src={process.env.PUBLIC_URL + '/images/CaseStudy-ManOfParts-Featured.jpg'} className="border-radius" alt='Man of Parts' />
                        </div>
                        <div className='col one_half'>
                        <span className='eyebrow yellow'>UX / UI Design</span>
                            <h3>Blending simplicity and style for user-friendly experiences</h3>
                            <p>Man of Parts was looking to enhance its online user experience while upholding its brand's high-end aesthetic. By understanding who their online users are, we're in the process of helping them build something great!</p>
                            <NavLink to="/man-of-parts" className="btn secondary case-study-alt">View Case Study</NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}